@import '../../../../scss/theme-bootstrap';

// Main Navigation - sections
.header-nav-section {
  &__section {
    position: relative;
    font-size: 12px;
    width: 100%;
    background-color: $color-black;
    @include breakpoint($landscape-up) {
      height: 100%;
      width: auto;
      float: $ldirection;
      display: flex;
      align-items: center;
      background-color: $color-white;
    }
    .header-nav-section__trigger:checked + & {
      background-color: $color-white;
      display: block;
      @include breakpoint($landscape-up) {
        background-color: transparent;
        overflow-y: inherit;
        display: flex;
        top: auto;
        z-index: auto;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked)
      ~ div
      .header-nav-section__trigger:not(:checked)
      + & {
      display: none;
      @include breakpoint($landscape-up) {
        display: flex;
        justify-content: space-around;
      }
    }
    &-label {
      color: $color-black;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: $gnav-mobile-link-height;
      line-height: 1.4;
      padding: 0 $gnav-mobile-hpad--outer;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        height: auto;
        padding: 0;
      }
      &:hover {
        color: $color-black;
        text-decoration: none;
        @include breakpoint($landscape-up) {
          box-shadow: 0 4px 0 0 $color-black;
        }
      }
      &--text {
        width: 100%;
        display: block;
        color: $color-white;
        cursor: pointer;
        line-height: $gnav-mobile-link-height;
        text-decoration: none;
        font-weight: 700;
        font-size: 25px;
        @include breakpoint($landscape-up) {
          font-family: $font--heading;
          font-size: 24px;
          line-height: 1;
          color: $color-black;
        }
        &:hover {
          color: $color-black;
          text-decoration: none;
        }
        .header-nav-section__trigger:checked ~ .header-nav-section__section & {
          text-align: center;
        }
        svg {
          .header-nav-section__trigger:checked ~ .header-nav-section__section & {
            display: none;
          }
        }
      }
      .header-nav-section__trigger:checked + .header-nav-section__section & {
        @include breakpoint($landscape-up) {
          box-shadow: 0 4px 0 0 $color-black;
          text-decoration: none;
        }
      }
    }
  }
  &__section-toggle {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: absolute;
      #{$rdirection}: 20px;
      top: 20px;
      z-index: $gnav-fixed-z-index;
    }
    svg {
      height: 14px;
      width: 14px;
    }
  }
  &__section-title {
    font-family: $font--heading;
    background-color: $color-black;
    color: $color-white;
    font-weight: 700;
    font-size: 30px;
    padding: 0 20px 20px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__section-content {
    background-color: $color-white;
    display: none;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    @include breakpoint($landscape-up) {
      @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
      z-index: $gnav-fixed-z-index;
      top: $gnav-height;
      max-width: $max-width-cr21;
      overflow: hidden;
      position: fixed;
      display: block;
    }
    @include breakpoint($max-width-cr21) {
      margin-#{$ldirection}: calc((100% - #{$max-width-cr21}) / 2);
    }
    // expanded state
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      display: block;
      height: auto;
      opacity: 1;
      position: relative;
      visibility: visible;
      overflow: hidden;
      @include breakpoint($landscape-up) {
        height: $gnav-content-max-height;
        position: fixed;
        z-index: $gnav-fixed-z-index;
        background-color: $color-white;
        overflow: visible;
      }
      .gnav-section-group > div:nth-child(1) {
        .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .gnav-section-formatter__link:hover ~ .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
        }
      }
    }
    .toolbar & {
      @include breakpoint($landscape-up) {
        top: $gnav-height + $toolbar-height;
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $utility-nav-height + $navbar-height;
      }
    }
    .toolbar.site-header-formatter--sticky &,
    .toolbar.gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $utility-nav-height + $navbar-height + $toolbar-height;
      }
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    fill: $color-white;
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 22px;
    height: $gnav-mobile-link-height;
    width: $gnav-icon--inline;
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
    // Arrow displays for mobile expanders only.
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      opacity: 0;
    }
  }
  &__section-link {
    pointer-events: none;
    color: inherit;
    @include breakpoint($landscape-up) {
      pointer-events: auto;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

body {
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .header-nav-section {
      display: none;
    }
  }
}
